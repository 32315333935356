import { mapGetters } from "vuex"
export default {
  name: "NotFound",
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      isLoggedIn           : "auth/isLoggedIn",
      loggedInUserRoleTypes: "auth/loggedInUserRoleTypes"
    }),
    helpCenterUrl() {
      return process.env.VUE_APP_HELP_CENTER_URL
    },
    showIssueOverview() {
      return this.isLoggedIn && this.loggedInUserRoleTypes.includes("issue")
    }
  },
  watch: {
  }
}
